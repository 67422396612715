const timelineInfo = [
    {
        type: 'education',
        dates: 'Oct 2019 - Feb 2020',
        organization: 'PDX Code Guild',
        location: 'Portland, OR',
        position: 'Student',
        description: 'A 14-week full-time, full-stack web design course.  Gained proficiency in HTML, CSS, JavaScript, and Python, using Vue.js and Django frameworks.',
        imageUrl: 'https://media-exp1.licdn.com/dms/image/C4E0BAQH7S3s2fGX2Lg/company-logo_100_100/0?e=1597881600&v=beta&t=RRL3Q209Ye1CSLI21RBqLN9rC4ejPV0GLIxWPIlr9RQ',
    },
    {
        type: 'work',
        dates: 'July 2017 - Aug 2019',
        organization: 'Amazon',
        location: 'Las Vegas, NV',
        position: 'Operations Manager',
        description: 'I was an Operations Manager at two different Amazon fulfillment centers in Las Vegas. After working in reverse logistics for the first year and a half, I transferred to a traditional fulfillment center operation where I was responsible for all daytime outbound operations and led a team of 200+ hourly associates and five salaried managers.',
        imageUrl: 'https://media-exp1.licdn.com/dms/image/C560BAQFdwVGpSOHmgw/company-logo_100_100/0?e=1597881600&v=beta&t=VgxqHoaKf0WbttfhtUbg_2kfQNlrw70wxkDbX8HAVEs',
    },
    {
        type: 'education',
        dates: 'June 2015 - June 2017',
        organization: 'UCLA: Anderson',
        location: 'Los Angeles, CA',
        position: 'MBA Candidate',
        description: 'I got my master of business administration degree after enrolling in the UCLA Anderson School of Management full-time MBA program. I followed the school\'s technology management track, which culminated in an 18-student, self-managed software development capstone project (we implemented a new student club portal using ASP.NET).',
        imageUrl: 'https://media-exp1.licdn.com/dms/image/C560BAQEie8vD0XYgWg/company-logo_100_100/0?e=1597881600&v=beta&t=OLJ3G-FVKtq8nNIwTDfHoP1XZqJMR4_Au_0ts854_QE',
    },
    {
        type: 'work',
        dates: 'June 2016 - Sept 2016',
        organization: 'Cisco',
        location: 'San Jose, CA',
        position: 'Product Manager Intern',
        description: 'I completed a paid, 10-week product manager internship at Cisco between my first and second year of business school. I worked with Cisco\'s router team on a project involving virtualizing hardware at branch locations.',
        imageUrl: 'https://media-exp1.licdn.com/dms/image/C4D0BAQHO_cO49I_FRA/company-logo_100_100/0?e=1597881600&v=beta&t=SPNoz2QaNXJSmqNus_l4fiou_rGSaTFulQC90DLz_5E',
    },
    {
        type: 'work',
        dates: 'May 2008 - May 2015',
        organization: 'US Navy',
        location: 'Bremerton, WA ▪ Cape Canaveral, FL',
        position: 'Submarine Officer',
        description: 'After a year and a half of nuclear power training, I served for three years as a division officer aboard the USS Maine in Washington state. I rotated to shore duty in 2013, where I was a port operations officer in Cape Canaveral, FL.',
        imageUrl: 'https://media-exp1.licdn.com/dms/image/C4E0BAQFWVXDWsH7EzQ/company-logo_100_100/0?e=1597881600&v=beta&t=8EGM_m04VoP97gqssOartpG011hhLDLdR8P8ReWbl3M',
    },
    {
        type: 'education',
        dates: 'June 2004 - May 2008',
        organization: 'US Naval Academy',
        location: 'Annapolis, MD',
        position: 'Student',
        description: 'Earned a bachelors of science, majoring in computer science. For extracurriculars, I was on the varsity offshore sailing team and played guitar in a student rock band that performed at pep rallies and tailgaters.',
        imageUrl: 'https://media-exp1.licdn.com/dms/image/C510BAQEc7soCuF6SvA/company-logo_100_100/0?e=1597881600&v=beta&t=9OAQ_yir-rrgrwQ57r0FmP01v-vCiwfRbmexi0UBVa8',
    },

]
export default timelineInfo